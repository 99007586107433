
const BeautySalonServices = ['Nail care', 'Eye care', 'Body shaping and fitness', 'Hair care', 'Make up', 'Facial treatment', 'Skin care', 'Waxing', 'Anti aging treatment',
    'Hair coloring', 'African hair braiding', 'Barber shop', 'Tattoo shop', 'Other']

const DentalService = ['Orthodontics', 'Cosmetic dentistry', 'Dentistry', 'Prosthodontics', 'Endodontics', 'Pediatrics dentistry',
    'Dental extraction', 'Dental restoration', 'Dental surgery', 'Denture', 'Dental implants', 'Teeth cleaning', 'Restoration dentistry',
    'Crown', 'Oral hygiene', 'Teeth whitening', 'Periodontics', 'Dantal braces', 'Invisalign', 'Root canal', 'Dental sealant', 'Root canal treatment',
    'Veneer', 'Bridge', 'Other']

const AutoRepairService = ['Computer diagnostic', 'Lube/oil/filter change', 'Radiator flush /fill services', 'Transmission fluid service', 'Timing belt replacement',
    'Air recharge & diagnostic service', 'Tire rotation & balance only', 'Battery replacement', 'Anti lock system diagnosis’s', 'Axle work bearing/seals',
    'Shock and struct replacement', 'Starter/alternator/heater cores', 'Suspension system services', 'Alignment', 'Anti lock system diagnosis',
    'Fleet service/maintenance', 'Towing', 'Roadside assistance', 'Other']

const AutoDetailingService = ['Engine cleaning', 'Exterior detailing', 'Full service package', 'Headlight restoration', 'Interior detailing',
    'Upholstery and carpet cleaning', 'Wheel and rim detailing', 'Window cleaning']

const CleanServices = ['Basic house cleaning', 'Deep cleaning', 'Laundry service', 'Green service', 'Sensitization service',
    'Ceiling and wall cleaning', 'Blind cleaning', 'Curtains cleaning', 'Carpet cleaning', 'Upholstery cleaning',
    'Chimney sweeping', 'Office cleaning', 'Disaster cleaning and restoration', 'Window cleaning', 'Pressure washing',
    'Restroom cleaning', 'Janitorial services', 'School cleaning', 'Medical cleaning', 'Sports cleaning', 'Move out cleaning',
    'Move in cleaning', 'Tile/floor cleaning', 'Duct cleaning', 'Gutter cleaning', 'After event cleaning', 'Airbnb cleaning',
    'Home organization', 'Apartment cleaning', 'Kitchen cleaning', 'Roof and gutter cleaning', 'Dryer vent and duct cleaning', 'Solar panel cleaning',
    'Other'
]

const HandymanServices = ['Assembly', 'Caulking', 'Cleaning', 'Home Proofing Services', 'Installation', 'Painting', 'Repair', 'Floors',
    'Gutters', 'Painting', 'Fencing', 'Drywall', 'Carpentry', 'Bathroom repair', 'Kitchen repair', 'Project for pets', 'Tile install', 'Preventive maintenance',
    'House security', 'Power washing', 'Furniture assembly', 'Drywall repair', 'Deck and patio', 'Garage', 'Basement', 'Other'
];


const LawyerServices = ['Commercial service', 'Employment service', 'Due diligence investigation', 'Civil litigation', 'Personal', 'Real estate law', 'Immigration',
    'Business law', 'Debt collection defense', 'Estate planning', 'Tenant/landlord legal issue', 'Business formation', 'Traffic ticket defense', 'Accident law', 'Bankruptcy law', 'Injury law',
    'Probates & successors', 'Criminal litigation', 'Criminal litigation', 'Sales & purchase of properties', 'Consultation for legal information and advice',
    'Consultation to review documents (such as agreements, leases, wills, corporation documents, do-it-yourself court forms)',
    'Mediation, arbitration, or other third party services', 'Preparation of documents', 'Representing clients in negotiations (possibly under “collaborative lawyering” agreement where both attorneys withdraw if either side chooses to litigate)',
    'Representing clients in court, arbitration, administrative and legislative hearings']

const WeddingPlannerService = ['Wedding Concept & Design(ThemeDecoration)', 'Venue Selection', 'Catering and Menu Selection', 'Music and Entertainment', 'Officiate',
    'Staging and Audio/Visual', 'Lighting Design', 'Invitations & Guest Management', 'Comprehensive detailed ‘Wedding Day Itinerary',
    'Wedding Invitation & Stationary', 'Reception Management', 'Trousseau & Personal Shopping', 'Accommodations Search & Selection',
    'Transportation', 'Contract Review & Negotiation', 'Rehearsal Coordination', 'Event Timeline', 'Budgeting', 'Photography/Videography',
    'Wedding Cinematography', 'Delivery and set-up of all wedding day items', 'Follow up with vendors', 'Personalized Attention to your wedding shopping (wedding trousseau)',
    'Cakes','Dress and attire','Event rental','Photo booths','Ceremony music','Bands','Favors and gifts','Travel agent','Jewelry']
 

const BusinessCousetingService = ['Strategy consulting', 'Marketing consulting', 'Operations consulting', 'Management consulting', 'Financial consulting',
    'HR consulting', 'Compliance consulting', 'IT/Technology consulting', 'Legal consultant', 'Social media consultant',
    'Sustainability consultant', 'Sales consultant', 'Wellness/Fitness consultant', 'Growth Marketing consultant', 'Career coaching consultancy',
    'PR consultancy', 'SEO consulting', 'Leadership consulting', 'Product development consulting', 'Design consultant', 'Brand consultant','Other']

const ElectricalServices = ['Panel upgrade', 'Generator installation', 'Electrical vehicle charger', 'Indoor and outdoor lighting', 
    'General home electric', 'Whole home surge protector', 'Commercial electric service', 'Electrical and building maintenance', 'Other'];

const FinancialServices = [
    'Financial planning and coaching',
    'Investments advisory services',
    'Brokerage account',
    'IRA',
    'Common stock & bonds',
    'Risk management & retirement strategies',
    'Personal home loan',
    'Investment property loans',
    'Equipment financing',
    'Settlement funding',
    'ITA plan',
    'Investor owner financing',
    'Bookkeeping services',
    'Business tax preparation',
    'Tax strategy and reduction planning',
    'S-Corp set up',
    'Business & LLC set up',
    'Financial analysis service',
    'Consultation',
    'Personal tax prep',
    'IRS debt settlement',
    'Credit repair',
    'Notary public',
    'Document prep',
    "Fractional CFO service",
    "Financial reporting",
    "Payroll management",
    "Find a financial expert",
    "Due diligence",
    "Valuation",
    "Merger and acquisitions advisory",
    "Corporate finance strategy",
    "Tax returns",
    "Tax identification services",
    "Tax compliance services",
    "Budgeting and forecasting",
    "Tax exemption services",
    "Cost analysis",
    "Stock analysis",
    "Personnel budget management",
    "Investment advisory",
    "Online trading lesson",
    "Investor sourcing",
    "Funding pitch presentations",
    "Fundraising consultations",
    'Other'
];
    
const PlumbingService = [
    "Backflow Prevention",
    "Commercial Service",
    "Drain Cleaning",
    "Drain Clogs",
    "Emergency Services",
    "Garbage Disposal Installation",
    "Gas Line Installation",
    "Gas Line Repair",
    "Hydro Jetting",
    "Leak Detection",
    "Repping",
    "Service Agreement",
    "Sewer Repair",
    "Sewer Replacement",
    "Slab Leaks",
    "Tankless Water Heaters",
    "Trenchless Sewer Repair",
    "Tunneling",
    "Water Filtration System",
    "Water Heater Installation",
    "Water Softeners",
    "Other"
];
const LandscapeServices = [
    "Landscape Design",
    "Lighting Design",
    "Landscape Style",
    "Outdoor Lighting",
    "Outdoor Kitchen And Fireplace",
    "Patio Covers And Pergolas",
    "Patio, Walkways And Driveways",
    "Fertilizer Application",
    "Weed Care",
    "Lawn Care Maintenance",
    "Landscape Maintenance",
    "Irrigation/Sprinkler System",
    "Turf Renovation & Installation",
    "Christmas/Holiday Lighting",
    "Planters Gardens",
    "Retaining Walls",
    "Sod Installation",
    "Ground Covers",
    "Clean Ups",
    "Sprinkler Repair",
    "Lawn Mowing",
    "Other"
];
const PaintingServices = [
    "Exterior House Painting",
    "Interior House Painting",
    "Cabinet Painting",
    "Epoxy Floors",
    "Deck And Fence Staining",
    "Brick Coating",
    "Exterior Commercial Painting",
    "Interior Commercial Painting",
    "Popcorn Ceiling Removal",
    "Wood Fence Installation",
    "Wallpaper Removal Service",
    "Drywall Repair Service",
    "Home Owner Association Specialist",
    "Custom Painting & Finishes",
    "Wrought Iron Fence Painting",
    "Other"
];

const HeatingVentilationAirConditioningServices = [
    "AC Maintenance",
    "AC Repair",
    "AC Installation",
    "Furnace Repair",
    "Furnace Installation",
    "Duct Service",
    "Indoor Air Quality",
    "Insulation Service",
    "AC Replacement",
    "Heating Installation",
    "Heating Replacement Service",
    "Air Filtration and Purifiers",
    "Water Filtration Services",
    "RO Systems",
    "Water Purification",
    "Water Softening",
    "Other"
  ];
const RoofingServices = [
    "Roof Repairs",
    "Roof Replacement",
    "Emergency Roof Repair",
    "Windows Repair",
    "Window Replacement",
    "Siding Repair",
    "Siding Replacement",
    "Door Repair",
    "Door Replacement",
    "Coating and Waterproofing",
    "Commercial Roof Replacement",
    "Commercial Roof Repair",
    "Roof Extend",
    "Other"
  ];

const ConcreteServices = [
    "Concrete Demolition",
    "Concrete Driveways",
    "Concrete Patio",
    "Concrete Sidewalks",
    "Concrete Slabs",
    "Stamped Concrete",
    "Hot Tub Pads",
    "Sports Courts",
    "Pool Decks",
    "Concrete Parking Lots",
    "Concrete Trash Pads",
    "Storage Pads",
    "Concrete Flooring",
    "Commercial Patios",
    "Other"
  ];
const TreeAndShrubServices = [
    "Tree Trimming",
    "Tree Removal",
    "Ball Moss Removal",
    "Stump Grinding",
    "Tree Risk Assessment",
    "Oak Wilt Analysis",
    "Brush and Lot Cleaning",
    "Emergency Tree Service",
    "Tree Inspection",
    "Mulching Services",
    "Storm Damage Tree Cleanup",
    "Tree Bracing",
    "Tree Cabling",
    "Tree Shaping",
    "Backfilling",
    "Land Clearing",
    "Plant Health Care",
    "Diagnostic Site Visit and Consulting Services",
    "Other"
];

const JunkRemovalServices = [
    "Garage Clean Out",
    "Hot Tub And Spa Removal",
    "Appliances Removal",
    "Construction Debris Removal",
    "Storage Unit Clean Outs",
    "Commercial Junk Removal",
    "Sofa/Loveseat Removal",
    "Dresser Removal",
    "Bed Removal",
    "Dining Table Removal",
    "Mattress Removal",
    "Head Boards Removal",
    "Futons Removal",
    "Estate Clean Out",
    "Eviction Cleanouts",
    "Garage Clean Out",
    "Hoarding Clean Outs",
    "Foreclosure Clean Out",
    "Business Clean Out",
    "Commercial Clean Out"  
  ];

  const MedicalServices = [
    "Anesthesiology",
    "Arthritis Care",
    "Pain Management",
    "Primary Care",
    "Pregnancy & Childbirth",
    "Rehabilitation Services",
    "Lab Services",
    "Ear, Nose and Throat",
    "Eye Care",
    "Gynecology",
    "Wound Care",
    "Men’s and Women’s Health",
    "Diabetes & Endocrinology",
    "Imaging & Radiology",
    "Infection Diseases",
    "Not Sure",
    "Other"
  ];

  const HomeRepairServices = [
    "Laptop And Computer Repair",
    "Lock, Lockout And Key Repair",
    "Shoe Repair",
    "Mobile Phone",
    "Jewelry And Watch Repair",
    "Refrigerator, Freezer, Or Ice Maker",
    "Range, Stovetop Or Oven",
    "TV Or Home Theater Accessory",
    "Central Vacuum",
    "Dishwasher",
    "BBQ Grill",
    "HVAC/ Furnace",
    "Washer / Dryer",
    "Other"
  ];
  const CareServices = [
    "Babysitter",
    "Nannies",
    "Daycares",
    "Senior Cares",
    "Pet Sitter",
    "Housekeepers",
    "Tutors",
    "Personal Care Services",
    "Long Term Adults Care",
    "Long Term Pediatric Care",
    "Nursing Care",
    "Memory Care",
    "Specialized Care",
    "Cancer Care",
    "Disability Care",
    "Assisted Living",
    "Veterans Home Care Services",
    "Other"
  ];
  const LessonServices = [
    "Computing",
    "Mathematics",
    "Reading and Grammar",
    "Life Skills",
    "Music & Drama",
    "Test Prep",
    "Arts and Humanities",
    "Dance",
    "Karate",
    "Sports & Fitness",
    "Guitar Lesson",
    "Training",
    "Online Tutoring",
    "In-Person Tutoring",
    "Other"
  ];
  const MovingStorageServices = [
    "Professional Movers",
    "Packing and Unpacking Services",
    "Professionals, Trained and Uniform Movers",
    "Clean Trucks with Proper Moving Pads and Equipment",
    "Residential Moving",
    "Corporate Moving",
    "Long Distance Moving",
    "Self Storage",
    "Business Storage",
    "Vehicle Storage",
    "Boat Storage",
    "TV Storage",
    "Other"
  ];

  const PlantAndFlowerServices = [
    "Wedding",
    "Anniversary",
    "Happy Birthday",
    "Get Well Soon",
    "It's a Boy",
    "Sympathy",
    "Thank You",
    "Crystal",
    "It's a Girl",
    "Love & Romance",
    "Funeral",
    "Fountains",
    "Outdoor Decor",
    "Pottery",
    "Gift Card",
    "Indoor Gardening",
    "Prayer Plants",
    "Speciality Plants",
    "Other"
  ];

  const TherapyCounselingServices = [
    "Individual Therapy",
    "Teen Therapy",
    "Couple Counseling",
    "Online Therapy",
    "Anger Management",
    "Life Coach",
    "Anxiety",
    "Bipolar Disorder",
    "Child/Teenager Counseling",
    "Depression",
    "Drug Abuse",
    "Eating Disorder",
    "Grief",
    "Personality Disorder",
    "Self Esteem",
    "Trauma & PTSD",
    "Surviving Romantic Breakup",
    "Divorce",
    "Other"
  ];

  const FinancingAccountingTaxationServices = [
    "Financial Planning and Coaching",
    "Investments Advisory Services",
    "Brokerage Account",
    "IRA",
    "Common Stock & Bonds",
    "Risk Management & Retirement Strategies",
    "Personal Home Loan",
    "Investment Property Loans",
    "Equipment Financing",
    "Settlement Funding",
    "ITA Plan",
    "Investor Owner Financing",
    "Bookkeeping Services",
    "Business Tax Preparation",
    "Tax Strategy and Reduction Planning",
    "S-Corp Set Up",
    "Business & LLC Set Up",
    "Financial Analysis Service",
    "Consultation",
    "Personal Tax Prep",
    "IRS Debt Settlement",
    "Credit Repair",
    "Notary Public",
    "Document Prep",
    "Fractional CFO service",
    "Financial reporting",
    "Payroll management",
    "Find a financial expert",
    "Due diligence",
    "Valuation",
    "Merger and acquisitions advisory",
    "Corporate finance strategy",
    "Tax returns",
    "Tax identification services",
    "Tax compliance services",
    "Budgeting and forecasting",
    "Tax exemption services",
    "Cost analysis",
    "Stock analysis",
    "Personnel budget management",
    "Investment advisory",
    "Online trading lesson",
    "Investor sourcing",
    "Funding pitch presentations",
    "Fundraising consultations",
    "Other"
  ];
  const InsuranceOptions = [
    "Auto Insurance",
    "Classic Car Insurance",
    "Motorcycle Insurance",
    "Snowmobile Insurance",
    "Boat Insurance",
    "SR-22 Insurance",
    "RV/Motorhome Insurance",
    "Renters Insurance",
    "ATV Insurance",
    "Homeowners Insurance",
    "Mobile/Manufactured Home Insurance",
    "Health Insurance",
    "Dental Insurance",
    "Telemedicine Insurance",
    "Pet Insurance",
    "Life Insurance",
    "Travel Club - AD&D Insurance",
    "Identity Theft Protection Insurance",
    "Mexican Car Insurance",
    "Travel Club - Hospital Indemnity Insurance",
    "Business Insurance",
    "Landlord Insurance",
    "Commercial Auto Insurance",
    "Rideshare Insurance",
    "Other"
  ];
  const LocksmithServices = [
    "Emergency locksmith",
    "Car locksmith",
    "Car key replacement",
    "Car lockout service",
    "Key FOB replacement",
    "Residential locksmith",
    "Commercial locksmith",
    "Access control",
    "Safe locksmith",
    "Door locksmith",
    "Key duplication",
    "Lockout service",
    "Lock change",
    "Mobile locksmith",
    "Other",
  ];
  const RemodelingServices = [
    "Deck & Patio Builder Remodeling",
    "Sealed Deck, Stairs & Railing Remodeling",
    "Wheelchair Ramps, Pergolas & Gazebos",
    "Fireplaces, Pools, Outdoor Kitchens",
    "Landscaping, Lighting & Electrical",
    "Room Additions",
    "Screened-in Porches",
    "Sunrooms",
    "Living Room & Kitchen Extensions",
    "Master Bed & Bath Expansion",
    "Kitchen Renovation",
    "Cabinet Installation",
    "New Countertops",
    "Sink Fixtures",
    "New Lighting",
    "Bathroom Remodeling",
    "New Floor & Tile",
    "New Wallpaper & Paint",
    "New Backsplash, Sink & Shower Fixtures",
    "Basement Remodeling",
    "Custom Entertainment Centers",
    "Children’s Play Spaces",
    "Kitchenette or Bar Design",
    "Finished Walls, Ceiling & Flooring",
    "Other"
  ];
  const JobSeekerProviderService = [
    'Sale Consultant', 'Bank Teller', 'Nursing', 'Tutor', 'Server', 'Registered Nurse',
      'Nurse Assistant', 'Material Handler', 'Forklift Operator', 'Office Assistant', 
      'Customer Service Specialist', 'Shipping Clerk', 'Delivery Driver', 
      'Warehouse Delivery Driver', 'Front Desk Receptionist', 'Truck Driver',
      'Manager', 'Executive Administrator', 'Printing Operator', 
      'Artificial Intelligence', 'Business Analytics', 'Business Intelligence', 
      'Cybersecurity', 'Data Desk Support', 'Network Engineering', 'Network Security',
      'Project Management', 'Quality Assurance', 'UI/UX', 'Other'
  ]
  const CleaningAndAlterationServices = [
    "Wash And Fold",
    "Leather Cleaning",
    "Wedding Gown Cleaning",
    "Commercial And Corporate Cleaning",
    "Fine Linen Cleaning",
    "Household Cleaning",
    "Laundry",
    "Dry Cleaning",
    "Rug Cleaning",
    "Fitting",
    "Suit, Denim And Shirt Alterations",
    "Pants Alteration",
    "Formal Dress Alteration",
    "Bridal Maid Dress Alterations",
    "Casual Dress Alteration",
    "Blouse Alterations",
    "Zipper Alteration",
    "Drapes And Jeans Alterations",
    "Homes Accessories Alterations",
    "Waist Coat And Vest Alterations",
    "Tuxedo Fitting",
    "Wedding Gown Alterations",
    "Dress And Gown Alterations",
    "Other"
];
const PestControlService = [
  "General pest control",
  "Cockroach control",
  "Wildlife control",
  "Cricket control",
  "Scorpio control",
  "Silverfish control",
  "Moth control",
  "Centipede & millipede control",
  "Ant control",
  "Mosquito control",
  "Rodent control",
  "Bed bug control",
  "Spider control",
  "Tick control",
  "Stinging pest control",
  "Other"
];

const ProfessionalOrganizer = [
  "Top to bottom decluttering and organizing in your home",
  "Closet design and layout",
  "Create new system",
  "Help with paper, time, and home management",
  "Redesign layout of furniture for better flow",
  "Preparing for sale, downsizing, resizing, or moving",
  "Removal of unwanted items through donation, recycling, discard, and shredding",
  "Unpacking and settling into a new home",
  "Packing and preparing for a move",
  "Holiday prep",
  "Small business organizing",
  "Senior citizen help",
  "Airbnb decorating",
  "D.I.Y customized plan",
  "Other",
];
module.exports = { BeautySalonServices, DentalService, AutoRepairService, AutoDetailingService, 
    CleanServices, HandymanServices, LawyerServices, WeddingPlannerService, BusinessCousetingService, 
    ElectricalServices, FinancialServices, PlumbingService, LandscapeServices, PaintingServices, 
    HeatingVentilationAirConditioningServices, RoofingServices, ConcreteServices, TreeAndShrubServices,
    JunkRemovalServices, MedicalServices, HomeRepairServices, CareServices, LessonServices, 
    MovingStorageServices, PlantAndFlowerServices, TherapyCounselingServices, FinancingAccountingTaxationServices,
    InsuranceOptions, LocksmithServices, RemodelingServices, JobSeekerProviderService,
    CleaningAndAlterationServices, PestControlService, ProfessionalOrganizer, 
 }
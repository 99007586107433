import * as actionTypes from "../actions/actionTypes";

const initialState = {
  loading: null,
  error: null,
  success: null,
  couponrankingMailSuccess:null,
  bussinesslisting: [],
  slide: [],
  categoryList: [],
  subCategoryList: [],
  bloglist: [],
  pictureList: [],
  businessDetails:{},
  businessListBySubcategory:[],
  ecommerceBusinessList:[],
  dealBusinessList:[],
  eCommBusinessList:[],
  couponBusinessList:[],
  storeFrontBusinessList:[],
  searchSuggestion:[],
  searchedBusinessList:[],
  policyTermConditionDetails:{},
  couporankingRatingList:[],
  blogDetails:{}
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.START_BUSSINESS_ACTION:
      return {
        ...state,
        loading: true,
        error: null
      };
    case actionTypes.BUSSINESS_ACTION_FAIL:
      return {
        ...state,
        loading: null,
        error: action.message,
        success: null,
      }
    case actionTypes.FETCH_BUSSINESS_LIST_SUCCESS:
      return {
        ...state,
        loading: null,
        bussinesslisting: action.listing,
        error: null,
        
      }
      case actionTypes.FETCH_BUSSINESS_SLIDE_SUCCESS:
        return {
          ...state,
          loading: null,
          error: null,
          slide1: action.slide1,
          slide2: action.slide2,
  
          slide3: action.slide3,
          slide4: action.slide4,
  
          slide5: action.slide5,
          slide6: action.slide6,
  
          banner: action.banner,
          success: action.message,
        }
      
    case actionTypes.FETCH_CATEGORY_LIST_SUCCESS:
      return {
        ...state,
        loading: null,
        categoryList: action.listing,
        error: null,
        success: action.message,
      }

    case actionTypes.GET_BLOG_LIST_SUCCESS:
      return {
        ...state,
        loading: null,
        bloglist: action.listing,
        error: null,
        success: action.message,
      }
    case actionTypes.ADD_BUSINESS_DETAIL_SUCCESS:
      return {
        ...state,
        loading: null,
        error: null,
        success: action.message,
      }
    case actionTypes.GET_PICTURE_LIST_SUCCESS:
      return {
        ...state,
        loading: null,
        error: null,
        pictureList: action.list,
        success: action.message,
      }
      case actionTypes.GET_BUSINESS_DETAILS_SUCCESS:
      return {
        ...state,
        loading: null,
        error: null,
        businessDetails: action.details,
        success: action.message,
      }
      case actionTypes.COUPON_RANKING_MAIL_SUCCESS:
      return {
        ...state,
        loading: null,
        error: null,
        couponrankingMailSuccess: action.message,
      }
      case actionTypes.GET_BUSINESS_LIST_BY_SUBCATEGORY:
      return {
        ...state,
        loading: null,
        businessListBySubcategory: action.listing,
        error: null,
        success: action.message,
      }
      case actionTypes.GET_ECOMMERCE_BUSINESSLIST:
      return {
        ...state,
        loading: null,
        ecommerceBusinessList: action.listing,
        error: null,
        success: action.message,
      }
      case actionTypes.GET_DEAL_BUSINESSLIST:
      return {
        ...state,
        loading: null,
        dealBusinessList: action.listing,
        error: null,
        success: action.message,
      }
      case actionTypes.GET_ECOMMERCE_PAGE_BUSINESSLIST:
      return {
        ...state,
        loading: null,
        eCommBusinessList: action.listing,
        error: null,
        success: action.message,
      }
      case actionTypes.GET_COUPON_PAGE_BUSINESSLIST:
      return {
        ...state,
        loading: null,
        couponBusinessList: action.listing,
        error: null,
        success: action.message,
      }
      case actionTypes.GET_STOREFRONT_BUSINESSLIST:
      return {
        ...state,
        loading: null,
        storeFrontBusinessList: action.listing,
        error: null,
        success: action.message,
      }
      case actionTypes.SEARCH_SUGGESTION:
      return {
        ...state,
        loading: null,
        searchSuggestion: action.listing,
        error: null,
        success: action.message,
      }
      case actionTypes.SEARCH_BUSINESS_LIST:
      return {
        ...state,
        loading: null,
        searchedBusinessList: action.listing,
        error: null,
        success: action.message,
      }
      case actionTypes.GET_POLICY_TERM_CONDITION:
      return {
        ...state,
        loading: null,
        policyTermConditionDetails: action.data,
        error: null,
        success: action.message,
      }
      case actionTypes.GET_COUPONRANKIG_RATING_LIST:
      return {
        ...state,
        loading: null,
        couporankingRatingList: action.listing,
        error: null,
        success: action.message,
      }
      case actionTypes.GET_BLOG_DETAILS_SUCCESS:
      return {
        ...state,
        loading: null,
        blogDetails: action.details,
        error: null,
        success: action.message,
      }
    default:
      return state;
  }
};

export default reducer;

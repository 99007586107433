import React from "react";
import { Routes, Route } from "react-router-dom";
import { Header } from "../navigation/Header";
import Home from "./Home";
import Footer from "../navigation/Footer";
import PricingPlan from "./PricingPlan";
import SaveCard from "./SaveCard";
import AddBusinessDetails from "./AddBusinessDetails"
import BusinessPicture from "./BusinessPicture";
import BusinessDetails from "./BusinessDetails";
import { DirectionMap } from "./DirectionMap";
import EmailPage from "./EmailPage";
import CouponRoyaltiRewards from "./CouponRoyaltiRewards";
import { ProductMenuDetails } from "./ProductMenuDetails";
import CardInfo from "./CardInfo";
import AllCategories from './AllCategories';
import CategoryBusiness from './CategoryBusiness'
import NearbyBusiness from './NearbyBusiness'
import CouponAndReward from './CouponAndReward'
import StoreFrontMarketplace from "./StoreFrontMarketplace";
import DealBusinessPage from "./DealBusinessPage";
import EcommerceBusiness from "./EcommerceBusiness";
import TextMessage from "./TextMessage";
import { RequestQuote } from "./RequestQuote";
import BookAppointment from "./BookAppointment";
import { CouponrankingForBusinesses } from "./CouponrankingForBusinesses"
import { CouponrankingForCustomers } from "./CouponrankingForCustomers";
import SearchedBusinessList from "./SearchedBusinessList";
import PrivacyPolicy from "./PrivacyPolicy";
import TermAndCondition from "./TermAndCondition";
import AboutUs from "./AboutUs";
import CouponrankingRating from "./CouponrankingRating";
import WhoWeAre from "./WhoWeAre";
import WhatWeDo from "./WhatWeDo";
import BlogDetails from "./BlogDetails";
import BlogList from "./BlogList";
import Thankyou from "./Thankyou";
// import NotFound from "./404";
import UserInfoForQuoteService from "../components/UserInfoForQuoteService";
import WelcomePage from "./WelcomePage";

export const Layout = () => {
    const token = localStorage.getItem('token');
    const userInfo = JSON.parse(localStorage.getItem('userInfo'));
    return (
        <>
            <Header />
            <div className="main-content">
                <Routes>
                    <Route exact path="/add-business-details" element={<AddBusinessDetails />} />
                    <Route exact path="/business-picture" element={<BusinessPicture />} />
                    <Route exact path="/business-details/:businessName/:subcategory/:businessAdd" element={<BusinessDetails />} />
                    <Route exact path="/product-menu-details/:businessName" element={<ProductMenuDetails />} />
                    {/* <Route exact path="/pricing-plan" element={<PricingPlan />} /> */}
                    <Route exact path="/pricing-plan" element={token && userInfo.user_type === 'business_user' ? <PricingPlan /> : ''} />
                    <Route exact path="/nearby-business/:address" element={<NearbyBusiness />} />
                    <Route exact path="/coupon-and-reward-program/:address" element={<CouponAndReward />} />
                    <Route exact path="/store-front-marketplace/:address" element={<StoreFrontMarketplace />} />
                    <Route exact path="/deal-business" element={<DealBusinessPage />} />
                    <Route exact path="/ecommerce-business" element={<EcommerceBusiness />} />
                    <Route exact path="/book-an-appointment/:businessName" element={<BookAppointment />} />
                    <Route exact path="/request-a-quote/:businessName" element={<RequestQuote />} />
                    <Route exact path="/save-card" element={<SaveCard />} />
                    <Route exact path="/welcome-page" element={<WelcomePage />} />
                    <Route exact path="/get-card-info" element={<CardInfo />} />
                    <Route exact path="/direction-to-business/:businessAddress" element={<DirectionMap />} />
                    <Route exact path="/send-email-to-business/:businessName" element={<EmailPage />} />
                    <Route exact path="/send-text-message-to-business/:businessName" element={<TextMessage />} />
                    <Route exact path="/royalty-rewards" element={<CouponRoyaltiRewards />} />
                    <Route exact path="/get-all-categories" element={<AllCategories />} />
                    <Route exact path="/get-business-by-category/:subcategory" element={<CategoryBusiness />} />
                    <Route exact path="/couponranking-for-business" element={<CouponrankingForBusinesses />} />
                    <Route exact path="/couponranking-for-customers" element={<CouponrankingForCustomers />} />
                    <Route exact path="/searched-business-list" element={<SearchedBusinessList />} />
                    <Route exact path="/blogs" element={<BlogList />} />
                    <Route exact path="/blog-details/:blogtitle" element={<BlogDetails />} />
                    <Route exact path="/privacy-policy" element={<PrivacyPolicy />} />
                    <Route exact path="/term-and-condition" element={<TermAndCondition />} />
                    <Route exact path="/about-us" element={<AboutUs />} />
                    <Route exact path="/rate-couponranking-by-business" element={token && userInfo.user_type === 'business_user' ? <CouponrankingRating /> : ''} />
                    <Route exact path="/who-we-are" element={<WhoWeAre />} />
                    <Route exact path="/what-we-do" element={<WhatWeDo />} />
                    <Route exact path="/thank-you" element={<Thankyou />} />
                    <Route exact path="/user-info-for-service" element={<UserInfoForQuoteService />} />
                    <Route exact path="/" element={<Home />} />
                    {/* <Route path="*"  element={<NotFound />}    /> */}
                </Routes>
            </div>
            <Footer />
        </>
    );
};

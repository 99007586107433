import * as actionTypes from "../actions/actionTypes";
import axios from "../../axios-config";

export const startbussinessAction = () => {
  return {
    type: actionTypes.START_BUSSINESS_ACTION
  };
};

export const bussinessActionFail = message => {
  return {
    type: actionTypes.BUSSINESS_ACTION_FAIL,
    message
  };
};

export const fetchbussinessListSUCCESS = (bussinessList, message = '') => {
  return {
    type: actionTypes.FETCH_BUSSINESS_LIST_SUCCESS,
    listing: bussinessList,
    message: message ? message : ''
  };
};


export const fetchbussinessListCouponSUCCESS = (slide1, slide2, slide3, slide4, slide5, slide6, banner, message = '') => {
  return {
    type: actionTypes.FETCH_BUSSINESS_SLIDE_SUCCESS,
    slide1: slide1,
    slide2: slide2,

    slide3: slide3,
    slide4: slide4,

    slide5: slide5,
    slide6: slide6,

    banner: banner,
    message: message ? message : ''
  };
};

export const getBusinessList = (data) => {
  return dispatch => {
    dispatch(startbussinessAction());
    const authCode = "Bearer " + localStorage.getItem("token");
    axios
      .post("/couponranking/get-businesslist", data, {
        headers: { Authorization: authCode }
      })
      .then(response => {
        if (response.status === 200) {
          if (response.data.status === 200) {
            dispatch(fetchbussinessListSUCCESS(response.data.data));
          } else {
            dispatch(bussinessActionFail(response.data.message));
          }
        } else {
          dispatch(bussinessActionFail(response.message));
        }
      })
      .catch(err => {
        dispatch(bussinessActionFail(err.message));
      });
  };
};


export const getBusinessListSlide = (data) => {
  return dispatch => {
    dispatch(startbussinessAction());
    const authCode = "Bearer " + localStorage.getItem("token");
    axios
      .post("/couponranking/get-businesslist-slide", data, {
        headers: { Authorization: authCode }
      })
      .then(response => {
        if (response.status === 200) {
          if (response.data.status === 200) {
            dispatch(fetchbussinessListCouponSUCCESS(response.data.slide1, 
                response.data.slide2,
                
                response.data.slide3,
                response.data.slide4,
                response.data.slide5,
                response.data.slide6,
                
                response.data.banner));
          } else {
            dispatch(bussinessActionFail(response.data.message));
          }
        } else {
          dispatch(bussinessActionFail(response.message));
        }
      })
      .catch(err => {
        dispatch(bussinessActionFail(err.message));
      });
  };
};

export const getSearchBusinessListSlide = (data) => {
  return dispatch => {
    dispatch(startbussinessAction());
    const authCode = "Bearer " + localStorage.getItem("token");
    axios
      .post("/couponranking/search-business-slides", data, {
        headers: { Authorization: authCode }
      })
      .then(response => {
        if (response.status === 200) {
          if (response.data.status === 200) {
            console.log(response.data);
            dispatch(fetchbussinessListCouponSUCCESS(response.data.slide1, 
                response.data.slide2,
                
                response.data.slide3,
                response.data.slide4,
                response.data.slide5,
                response.data.slide6,
                
                response.data.banner));
          } else {
            dispatch(bussinessActionFail(response.data.message));
          }
        } else {
          dispatch(bussinessActionFail(response.message));
        }
      })
      .catch(err => {
        dispatch(bussinessActionFail(err.message));
      });
  };
};
export const getCategoryDataSlides = (data) => {
  return dispatch => {
    dispatch(startbussinessAction());
    const authCode = "Bearer " + localStorage.getItem("token");
    axios
      .post("/couponranking/category-slides", data, {
        headers: { Authorization: authCode }
      })
      .then(response => {
        if (response.status === 200) {
          if (response.data.status === 200) {
            console.log(response.data);
            dispatch(fetchbussinessListCouponSUCCESS(response.data.slide1, 
                response.data.slide2,
                
                response.data.slide3,
                response.data.slide4,
                response.data.slide5,
                response.data.slide6,
                
                response.data.banner));
          } else {
            dispatch(bussinessActionFail(response.data.message));
          }
        } else {
          dispatch(bussinessActionFail(response.message));
        }
      })
      .catch(err => {
        dispatch(bussinessActionFail(err.message));
      });
  };
};
export const fetchCategoryListSUCCESS = (list) => {
  return {
    type: actionTypes.FETCH_CATEGORY_LIST_SUCCESS,
    listing: list,
    // message: message ? message : ''
  };
};


export const getCategoryList = () => {
  return dispatch => {
    dispatch(startbussinessAction());
    // const authCode = "Bearer " + localStorage.getItem("token");
    axios
      .get("/couponranking/get-category-list", {
        // headers: { Authorization: authCode }
      })
      .then(response => {
        if (response.status === 200) {
          if (response.data.status === 200) {
            dispatch(fetchCategoryListSUCCESS(response.data.data));
          } else {
            dispatch(bussinessActionFail(response.data.message));
          }
        } else {
          dispatch(bussinessActionFail(response.message));
        }
      })
      .catch(err => {
        dispatch(bussinessActionFail(err.message));
      });
  };
};

export const fetchBlogListSUCCESS = (list) => {
  return {
    type: actionTypes.GET_BLOG_LIST_SUCCESS,
    listing: list,
    // message: message ? message : ''
  };
};


export const getBlogList = () => {
  return dispatch => {
    dispatch(startbussinessAction());
    // const authCode = "Bearer " + localStorage.getItem("token");
    axios
      .get("/webCustomer/get-blog-list", {
        // headers: { Authorization: authCode }
      })
      .then(response => {
        if (response.status === 200) {
          if (response.data.status === 200) {
            dispatch(fetchBlogListSUCCESS(response.data.data));
          } else {
            dispatch(bussinessActionFail(response.data.message));
          }
        } else {
          dispatch(bussinessActionFail(response.message));
        }
      })
      .catch(err => {
        dispatch(bussinessActionFail(err.message));
      });
  };
};

export const addBusinessDetailsSuccess = (message) => {
  return {
    type: actionTypes.ADD_BUSINESS_DETAIL_SUCCESS,
    message: message
  };
};

export const AddBusinessDetails = (data) => {
  return dispatch => {
    dispatch(startbussinessAction());
    const authToken = "Bearer " + localStorage.getItem("token");
    axios
      .post("/couponranking/add-business-details", data, {
        headers: { Authorization: authToken }
      })
      .then(response => {
        if (response.status === 200) {
          if (response.data.status === 200) {
            dispatch(addBusinessDetailsSuccess(response.data.message));
            const load = () => { window.location = '/business-picture' }
            setTimeout(load, 2000);
          } else {
            dispatch(bussinessActionFail(response.data.message));
          }
        } else {
          dispatch(bussinessActionFail(response.message));
        }
      })
      .catch(err => {
        dispatch(bussinessActionFail(err.message));
      });
  };
};

export const getPictureSucess = (list, message) => {
  return {
    type: actionTypes.GET_PICTURE_LIST_SUCCESS,
    list: list,
    message: message
  };
};

export const getPictureList = () => {
  return dispatch => {
    dispatch(startbussinessAction());
    const authToken = "Bearer " + localStorage.getItem("token");
    axios
      .get("/couponranking/get-pictures", {
        headers: { Authorization: authToken }
      })
      .then(response => {
        if (response.status === 200) {
          if (response.data.status === 200) {
            dispatch(getPictureSucess(response.data.data));
          } else {
            dispatch(bussinessActionFail(response.data.message));
          }
        } else {
          dispatch(bussinessActionFail(response.message));
        }
      })
      .catch(err => {
        dispatch(bussinessActionFail(err.message));
      });
  };
};

export const uploadBusinessPicture = (data) => {
  return dispatch => {
    dispatch(startbussinessAction());
    const authToken = "Bearer " + localStorage.getItem("token");
    axios
      .post("/couponranking/upload-business-picture", data, {
        headers: { Authorization: authToken }
      })
      .then(response => {
        if (response.status === 200) {
          if (response.data.status === 200) {
            dispatch(getPictureSucess(response.data.data, response.data.message));
            const load = () => { window.location.reload() }
            setTimeout(load, 2000);
          } else {
            dispatch(bussinessActionFail(response.data.message));
          }
        } else {
          dispatch(bussinessActionFail(response.message));
        }
      })
      .catch(err => {
        dispatch(bussinessActionFail(err.message));
      });
  };
};

export const deleteBusinessPicture = (data) => {
  return dispatch => {
    dispatch(startbussinessAction());
    const authToken = "Bearer " + localStorage.getItem("token");
    axios
      .post("/couponranking/delete-business-picture", data, {
        headers: { Authorization: authToken }
      })
      .then(response => {
        if (response.status === 200) {
          if (response.data.status === 200) {
            dispatch(getPictureSucess(response.data.data, response.data.message));
            const load = () => { window.location.reload() }
            setTimeout(load, 2000);
          } else {
            dispatch(bussinessActionFail(response.data.message));
          }
        } else {
          dispatch(bussinessActionFail(response.message));
        }
      })
      .catch(err => {
        dispatch(bussinessActionFail(err.message));
      });
  };
};

export const getBusinessDetailsSuccess = (details) => {
  return {
    type: actionTypes.GET_BUSINESS_DETAILS_SUCCESS,
    details: details
  };
};

export const getBusinessDetails = (data) => {
  return dispatch => {
    dispatch(startbussinessAction());
    const authToken = "Bearer " + localStorage.getItem("token");
    axios
      .post("/couponranking/get-business-details", data, {
        headers: { Authorization: authToken }
      })
      .then(response => {
        if (response.status === 200) {
          if (response.data.status === 200) {
            dispatch(getBusinessDetailsSuccess(response.data.data));
          } else {
            dispatch(bussinessActionFail(response.data.message));
          }
        } else {
          dispatch(bussinessActionFail(response.message));
        }
      })
      .catch(err => {
        dispatch(bussinessActionFail(err.message));
      });
  };
};

export const couponrankingMailSuccess = (message) => {
  return {
    type: actionTypes.COUPON_RANKING_MAIL_SUCCESS,
    message: message
  };
};

export const clickPerCount = (data, toast, navigate) => {
  return dispatch => {
    dispatch(startbussinessAction());
    const authToken = "Bearer " + localStorage.getItem("token");
    axios
      .post("/couponranking/create-per-click-count", data, {
        headers: { Authorization: authToken }
      })
      .then(response => {
        if (response.status === 200) {
          if (response.data.status === 200) {
            dispatch(couponrankingMailSuccess(response.data.message))
            if (data.clickType === 'email') {
              const load = () => { window.location.replace('/') }
              setTimeout(load, 2000);
            } else if (data.clickType === 'text') {
              toast.success(response.data.message, {
                autoClose: 500,
                hideProgressBar: true,
              })
              const load = () => { window.location.replace('/') }
              setTimeout(load, 2000);
            } else if (data.clickType === 'bookAppointment' || data.clickType === 'requestQuote') {
              toast.success(response.data.message, {
                autoClose: 700,
                hideProgressBar: true,
              })
              const load = () => { navigate('/thank-you', { state: { customerName: data.customerName, businessName: data.businessName }, replace: true }) }
              setTimeout(load, 2000);
            }
          } else {
            if (data.clickType === 'text' || data.clickType === 'bookAppointment' || data.clickType === 'requestQuote') {
              toast.error(response.data.message, {
                autoClose: 500,
                hideProgressBar: true,
              })
            }
            dispatch(bussinessActionFail(response.data.message));
          }
        } else {
          if (data.clickType === 'text') {
            toast.error(response.data.message, {
              autoClose: 500,
              hideProgressBar: true,
            })
          }
          dispatch(bussinessActionFail(response.message));
        }
      })
      .catch(err => {
        dispatch(bussinessActionFail(err.message));
      });
  };
};

export const sendOtpMail = (data, toast) => {
  return dispatch => {
    dispatch(startbussinessAction());
    const authToken = "Bearer " + localStorage.getItem("token");
    axios
      .post("/couponranking/verify-email-with-otp", data, {
        headers: { Authorization: authToken }
      })
      .then(response => {
        if (response.status === 200) {
          if (response.data.status === 200) {
            toast.success(response.data.message, {
              autoClose: 500,
              hideProgressBar: true,
            })
          } else {
            toast.error(response.data.message, {
              autoClose: 500,
              hideProgressBar: true,
            })
          }
        } else {
          toast.error(response.data.message, {
            autoClose: 500,
            hideProgressBar: true,
          })
        }
      })
      .catch(err => {
        dispatch(bussinessActionFail(err.message));
      });
  };
};

export const getBusinessBySubcategorySuccess = (bussinessList, message = '') => {
  return {
    type: actionTypes.GET_BUSINESS_LIST_BY_SUBCATEGORY,
    listing: bussinessList,
    message: message ? message : ''
  };
};

export const getBusinessListBySubCategory = (data) => {
  return dispatch => {
    dispatch(startbussinessAction());
    const authCode = "Bearer " + localStorage.getItem("token");
    axios
      .post("/couponranking/get-businesslist-subcategorywise", data, {
        headers: { Authorization: authCode }
      })
      .then(response => {
        if (response.status === 200) {
          if (response.data.status === 200) {
            dispatch(getBusinessBySubcategorySuccess(response.data.data));
          } else {
            dispatch(bussinessActionFail(response.data.message));
          }
        } else {
          dispatch(bussinessActionFail(response.message));
        }
      })
      .catch(err => {
        dispatch(bussinessActionFail(err.message));
      });
  };
};


export const getEcommerceBusinessSuccess = (bussinessList, message = '') => {
  return {
    type: actionTypes.GET_ECOMMERCE_BUSINESSLIST,
    listing: bussinessList,
    message: message ? message : ''
  };
};

export const getEcommerceBusinessList = (data) => {
  return dispatch => {
    dispatch(startbussinessAction());
    const authCode = "Bearer " + localStorage.getItem("token");
    axios
      .post("/couponranking/get-ecommerce-businesslist", data, {
        headers: { Authorization: authCode }
      })
      .then(response => {
        if (response.status === 200) {
          if (response.data.status === 200) {
            dispatch(getEcommerceBusinessSuccess(response.data.data));
          } else {
            dispatch(bussinessActionFail(response.data.message));
          }
        } else {
          dispatch(bussinessActionFail(response.message));
        }
      })
      .catch(err => {
        dispatch(bussinessActionFail(err.message));
      });
  };
};


export const getDealBusinessSuccess = (bussinessList, message = '') => {
  return {
    type: actionTypes.GET_DEAL_BUSINESSLIST,
    listing: bussinessList,
    message: message ? message : ''
  };
};

export const getDealBusinessList = (data) => {
  return dispatch => {
    dispatch(startbussinessAction());
    const authCode = "Bearer " + localStorage.getItem("token");
    axios
      .post("/couponranking/get-deal-and-ecommerce-businesslist", data, {
        headers: { Authorization: authCode }
      })
      .then(response => {
        if (response.status === 200) {
          if (response.data.status === 200) {
            // console.log(response.data.data,'heyyyyyyyyyyyy')
            dispatch(getDealBusinessSuccess(response.data.data));
          } else {
            dispatch(bussinessActionFail(response.data.message));
          }
        } else {
          dispatch(bussinessActionFail(response.message));
        }
      })
      .catch(err => {
        dispatch(bussinessActionFail(err.message));
      });
  };
};

export const getEcomPageBusinessSuccess = (bussinessList, message = '') => {
  return {
    type: actionTypes.GET_ECOMMERCE_PAGE_BUSINESSLIST,
    listing: bussinessList,
    message: message ? message : ''
  };
};

export const getEcomPageBusinessList = (data) => {
  return dispatch => {
    dispatch(startbussinessAction());
    const authCode = "Bearer " + localStorage.getItem("token");
    axios
      .post("/couponranking/get-deal-and-ecommerce-businesslist", data, {
        headers: { Authorization: authCode }
      })
      .then(response => {
        if (response.status === 200) {
          if (response.data.status === 200) {
            dispatch(getEcomPageBusinessSuccess(response.data.data));
          } else {
            dispatch(bussinessActionFail(response.data.message));
          }
        } else {
          dispatch(bussinessActionFail(response.message));
        }
      })
      .catch(err => {
        dispatch(bussinessActionFail(err.message));
      });
  };
};

export const getCouponBusinessSuccess = (bussinessList, message = '') => {
  return {
    type: actionTypes.GET_COUPON_PAGE_BUSINESSLIST,
    listing: bussinessList,
    message: message ? message : ''
  };
};

export const getCouponBusinessList = (data) => {
  return dispatch => {
    dispatch(startbussinessAction());
    const authCode = "Bearer " + localStorage.getItem("token");
    axios
      .post("/couponranking/get-coupon-and-storefront-businesslist", data, {
        headers: { Authorization: authCode }
      })
      .then(response => {
        if (response.status === 200) {
          if (response.data.status === 200) {
            dispatch(getCouponBusinessSuccess(response.data.data));
          } else {
            dispatch(bussinessActionFail(response.data.message));
          }
        } else {
          dispatch(bussinessActionFail(response.message));
        }
      })
      .catch(err => {
        dispatch(bussinessActionFail(err.message));
      });
  };
};

export const getstoreFrontBusinessSuccess = (bussinessList, message = '') => {
  return {
    type: actionTypes.GET_STOREFRONT_BUSINESSLIST,
    listing: bussinessList,
    message: message ? message : ''
  };
};

export const getstoreFrontBusinessList = (data) => {
  return dispatch => {
    dispatch(startbussinessAction());
    const authCode = "Bearer " + localStorage.getItem("token");
    axios
      .post("/couponranking/get-coupon-and-storefront-businesslist", data, {
        headers: { Authorization: authCode }
      })
      .then(response => {
        if (response.status === 200) {
          if (response.data.status === 200) {
            dispatch(getstoreFrontBusinessSuccess(response.data.data));
          } else {
            dispatch(bussinessActionFail(response.data.message));
          }
        } else {
          dispatch(bussinessActionFail(response.message));
        }
      })
      .catch(err => {
        dispatch(bussinessActionFail(err.message));
      });
  };
};


export const getsearchSuggestionList = (list, message = '') => {
  return {
    type: actionTypes.SEARCH_SUGGESTION,
    listing: list,
    message: message ? message : ''
  };
};

export const getsearchSuggestion = (data) => {
  return dispatch => {
    dispatch(startbussinessAction());
    const authCode = "Bearer " + localStorage.getItem("token");
    axios
      .post("/couponranking/search-suggestion", data, {
        headers: { Authorization: authCode }
      })
      .then(response => {
        if (response.status === 200) {
          if (response.data.status === 200) {
            dispatch(getsearchSuggestionList(response.data.data));
          } else {
            dispatch(bussinessActionFail(response.data.message));
          }
        } else {
          dispatch(bussinessActionFail(response.message));
        }
      })
      .catch(err => {
        dispatch(bussinessActionFail(err.message));
      });
  };
};

export const getsearcedBusiness = (list, message = '') => {
  return {
    type: actionTypes.SEARCH_BUSINESS_LIST,
    listing: list,
    message: message ? message : ''
  };
};

export const getsearchBusinessList = (data, navigate) => {
  return dispatch => {
    dispatch(startbussinessAction());
    const authCode = "Bearer " + localStorage.getItem("token");
    axios
      .post("/couponranking/search-business", data, {
        headers: { Authorization: authCode }
      })
      .then(response => {
        if (response.status === 200) {
          if (response.data.status === 200) {
            dispatch(getsearcedBusiness(response.data.data));
            navigate('/searched-business-list', { state: {
              data: data.subCategoryName
            }, replace: true })
            // searched-business-list
          } else {
            dispatch(bussinessActionFail(response.data.message));
          }
        } else {
          dispatch(bussinessActionFail(response.message));
        }
      })
      .catch(err => {
        dispatch(bussinessActionFail(err.message));
      });
  };
};

export const getPolicyTermCond = (data, message = '') => {
  return {
    type: actionTypes.GET_POLICY_TERM_CONDITION,
    data: data,
    message: message ? message : ''
  };
};

export const getPolicyTermConditionDetails = () => {
  return dispatch => {
    const authCode = "Bearer " + localStorage.getItem("token");
    axios
      .get("/couponranking/get-policy-term-condition", {
        headers: { Authorization: authCode }
      })
      .then(response => {
        if (response.status === 200) {
          if (response.data.status === 200) {
            dispatch(getPolicyTermCond(response.data.data));
          } else {
            dispatch(bussinessActionFail(response.data.message));
          }
        } else {
          dispatch(bussinessActionFail(response.message));
        }
      })
      .catch(err => {
        dispatch(bussinessActionFail(err.message));
      });
  };
};

export const couponrankingRatinglist = (list, message = '') => {
  return {
    type: actionTypes.GET_COUPONRANKIG_RATING_LIST,
    listing: list,
    message: message ? message : ''
  };
};

export const getCouponrankingRatingList = (data) => {
  return dispatch => {
    dispatch(startbussinessAction());
    const authCode = "Bearer " + localStorage.getItem("token");
    axios
      .post("/couponranking/get-couponranking-rating-list", data, {
        headers: { Authorization: authCode }
      })
      .then(response => {
        if (response.status === 200) {
          if (response.data.status === 200) {
            dispatch(couponrankingRatinglist(response.data.data));
          } else {
            dispatch(bussinessActionFail(response.data.message));
          }
        } else {
          dispatch(bussinessActionFail(response.message));
        }
      })
      .catch(err => {
        dispatch(bussinessActionFail(err.message));
      });
  };
};

export const blogDetailsSuccess = (details) => {
  return {
    type: actionTypes.GET_BLOG_DETAILS_SUCCESS,
    details: details,
  };
};

export const getBlogDetails = (data) => {
  return dispatch => {
    dispatch(startbussinessAction());
    const authCode = "Bearer " + localStorage.getItem("token");
    axios
      .post("/couponranking/get-blog-details", data, {
        headers: { Authorization: authCode }
      })
      .then(response => {
        if (response.status === 200) {
          if (response.data.status === 200) {
            dispatch(blogDetailsSuccess(response.data.data));
          } else {
            dispatch(bussinessActionFail(response.data.message));
          }
        } else {
          dispatch(bussinessActionFail(response.message));
        }
      })
      .catch(err => {
        dispatch(bussinessActionFail(err.message));
      });
  };
};




